import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ReactComponent as FacebookIcon } from "../../images/whatsapp.svg";
import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import NewHeader from "components/headers/newHeader.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300 no-underline`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}F
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("https://st2.depositphotos.com/3944627/8253/i/600/depositphotos_82533520-stock-photo-surveillance-security-camera-or-cctv.jpg");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-[4rem] text-center sm:text-[5rem] lg:text-[7rem] xl:text-[8rem] font-black text-gray-100 leading-snug sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SubHeading = styled.h2`
  ${tw`text-xl text-center sm:text-2xl lg:text-3xl xl:text-4xl font-black text-gray-100 leading-snug sm:mt-0`}
  span {
    ${tw`inline-block`}
  }
`;

const Information = styled.h3`
  ${tw`hidden sm:block text-sm text-center sm:text-lg lg:text-lg xl:text-xl text-white sm:w-4/5 lg:w-3/5 sm:mt-0 mx-auto text-justify`}
  
`;

const PrimaryAction = tw.button`rounded-full border bg-green-600 py-4 px-5 text-white transition-all hover:bg-white hover:text-black text-center text-sm flex items-center justify-center font-semibold sm:mt-[-3em]`;
const LinkImage = tw.img`h-4 w-4 mr-2`;


export default () => {
  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <NewHeader/>
        <Content>
          <Heading>
              SAP INFO
              <SubHeading>Security, Data and Voice</SubHeading>
              <Information>
              Your one-stop solution for comprehensive security, communication, and networking needs. We specialize in CCTV, computer accessories, intrusion systems, fire alarms, networking, and more. Exceptional service and support guaranteed.
              </Information>
          </Heading>
          <a href="https://wa.me/+919428705088" target="_blank" rel="noopener noreferrer">
          <PrimaryAction>
          <FacebookIcon />
          Whatsapp Us
          </PrimaryAction></a>
        </Content>
      </HeroContainer>
    </Container>
  );
};
