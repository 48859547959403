import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 mb-5`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p` font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-0`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-primary-500`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;
const Heading = tw(
  SectionHeading
)` font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;


export default () => {
  const cards = [
    {
      imageSrc:
        "https://4.imimg.com/data4/HW/PW/MY-1683133/hikvision-cctv-camera.jpg",
     
      title: "Security & Surveillance",
      description:
        "Enhancing security with cutting-edge surveillance systems for comprehensive monitoring and protection. Complete installation and service.",
      url: "https://timerse.com"
    },

    {
      imageSrc:
        "https://media.discordapp.net/attachments/783383778562801675/1188482348128419900/wan-network.png?ex=659aaf70&is=65883a70&hm=afa6bf4f0dd5b2d5f278cd408fd28e3cdc6a245110345f24c7cb2bedaa0d8e35&=&format=webp&quality=lossless&width=637&height=662",
     
      title: "Fingerprint Security Systems",
      description:
        "Streamlining workforce management with efficient time tracking, attendance solutions and access control system.",
      url: "https://timerse.com"
    },
    {
      imageSrc:
        "https://media.discordapp.net/attachments/783383778562801675/1188483066017095791/wan-network.png?ex=659ab01b&is=65883b1b&hm=cc76e5cbfe97c5b994bf191367c38dc444ae8979fa8a3c7cc2af45622b9a4132&=&format=webp&quality=lossless&width=637&height=662",
     
      title: "Unified Communication System",
      description:
        "Unified Communication is a terminology which integrates different communication medium like Status- based Voice and Video Communication, Mobility, Instant Messaging, Fax and Voice Mail under one Umbrella.",
      url: "https://timerse.com"
    },
    {
      imageSrc:
        "https://5.imimg.com/data5/SELLER/Default/2020/9/CO/VC/AT/51873676/fire-alarm-system.jpg",
    
      title: "Fire Security",
      description:
        "Ensuring safety with advanced fire alarm systems for prompt detection and response.",
      url: "https://timerse.com"
    },
    {
      imageSrc:
        "https://media.discordapp.net/attachments/783383778562801675/1188475010969518080/wan-network.png?ex=659aa89b&is=6588339b&hm=6cd6b12b2b9e390c85b7fe818db5367305cf1f92cf507ca506b1dbf0227f9ff6&=&format=webp&quality=lossless&width=575&height=662",
    
      title: "Enterprise Network",
      description:
        "Unified Applications such as Communication, Telepresence & Collaboration, E-mail, Internet, Data Network, Video Surveillance, Access Control system, Public Addressing System, and Audio Visuals system.",
      url: "https://timerse.com"
    }
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <Heading>Our Products</Heading>
          <HeadingDescription>
            Here are some of our most popular products in the market curated by professionals.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={"https://wa.me/+919428705088"} target="_blank" rel="noopener noreferrer">Contact Us</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
    </Container>
  );
};
