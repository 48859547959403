import React from "react";
import "style.css"
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

// import Hero from "components/hero/BackgroundAsImage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent"
import Events from "components/features/VerticalWithAlternateImageAndText.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <Events/>
    <MainFeature />
    <Features />
    <MainFeature2 />
    
    <Testimonial
      subheading="Introducing"
      heading={
        <>
          Our lovely <span tw="text-primary-500">Founders.</span>
        </>
      }
      description="Here are what the amazing founders are saying about the nature and thier product."
      testimonials={[
        {
          imageSrc:
            "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "https://media.discordapp.net/attachments/783383778562801675/1130379295014002799/shreyash.jpg?width=507&height=551",
          quote:
            "Connectivity and security go hand in hand, building a strong foundation for business success with SAP INFO!",
          customerName: "Shreyash Vachhani",
          customerTitle: "Founder, sapinfo.co.in"
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "https://media.discordapp.net/attachments/783383778562801675/1130379294753951775/nitya.png?width=705&height=701",
          quote:
            "Unlocking the power of surveillance, SAP INFO ensures your peace of mind with cutting-edge solutions that provide unparalleled visibility, protection, and control.",
          customerName: "Nitya Vachhani",
          customerTitle: "Founder Son, sapinfo.co.in"
        }
      ]}
      textOnLeft={true}
    />
   
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
);
