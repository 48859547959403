import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

const Container = tw.div`relative `;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-3 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2  border-primary-500 rounded-lg`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center relative z-10`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-green-300`}
  }

  .description {
    ${tw`mt-3 font-semibold text-white text-sm leading-loose`}
  }
`;

const CardContainer = styled.div(({ bgImage }) => [
  tw`relative bg-center bg-cover my-6 mx-6 rounded-lg`,
  css`
    &:before {
      content: "";
      ${tw`absolute inset-0 bg-black opacity-50 rounded-lg`}
    }
  `,
  css`
    background-image: url(${bgImage});
  `,
]);

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: 'https://img.freepik.com/premium-photo/security-cctv-camera-office-building_117856-621.jpg?w=360',
      title: "Intelligent Surveillance Solutions",
      description: "Our expertise lies in delivering advanced surveillance systems that leverage cutting-edge technology and analytics for maximum coverage, real-time monitoring, and proactive threat detection."
    },
    { imageSrc: "https://i0.wp.com/www.cctvsg.net/wp-content/uploads/2019/11/ethernet-cables.jpg", title: "Unified Network Infrastructure" ,
    description: "We specialize in scalable structured cabling solutions, ensuring seamless connectivity and supporting unified communication applications with meticulous planning and efficient installation."
  },
    { imageSrc: "https://blog.bizvibe.com/wp-content/uploads/2020/05/largest-telecom-companies.jpg", title: "Collaborative Telepresence Solutions" ,
    description: "Experience seamless collaboration and enhanced productivity with our immersive video conferencing technologies and interactive tools, enabling effective communication across geographically dispersed teams."
  },
    { imageSrc: "https://5.imimg.com/data5/PH/PZ/AH/SELLER-11151445/biometric-attendance-system-500x500.jpg", title: "Enterprise Network and Security",
    description: "Our comprehensive network solutions prioritize data integrity, confidentiality, and accessibility, with robust architecture, efficient data routing, secure access controls, and advanced threat prevention mechanisms."
  },
    { imageSrc: "https://www.racksolutions.com/news//app/uploads/velcro-vs-zip-tie-cable-management.jpg", title: "Reliable Server and Storage Solutions" ,
    description: "Benefit from our reliable server and storage systems, designed for optimal resource allocation, high availability, data integrity, and seamless access to critical applications."
  },
    { imageSrc: "https://instrumentationtools.com/wp-content/uploads/2022/06/What-is-Multi-Sensor-Detector.png", title: "Comprehensive Fire and Safety Systems" ,
    description: "We prioritize the safety of people and infrastructure through our comprehensive fire detection and prevention systems, incorporating advanced safety measures for compliance and risk reduction."
  }
  ];

  return (
    <Container id= "aboutsap">
      <ThreeColumnContainer>
        <Heading>Why choose <span tw="text-primary-500">SAP INFO?</span></Heading>
        {cards.map((card, i) => (
          <CardContainer key={i} bgImage={card.imageSrc}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || ""}
                </p>
              </span>
            </Card>
          </CardContainer>
        ))}
      </ThreeColumnContainer>
    </Container>
  );
};
